/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

// Components
import ParseContent from 'components/shared/ParseContent'

const Container = styled.div`
  width: 100%;
  display: flex;
  font-size: ${(props) => props.theme.font.size.xl};
  font-weight: ${(props) => props.theme.font.weight.l};
  font-family: ${(props) => props.theme.font.family.secondary};
  margin-left: calc(50% - 496px);
  @media screen and (max-width: 1200px) {
    margin-left: calc(50% - 400px);
  }
  @media screen and (max-width: 992px) {
    margin-left: calc(50% - 300px);
  }
  @media screen and (max-width: 768px) {
    margin-left: calc(50% - 210px);
  }
  @media screen and (max-width: 576px) {
    margin-left: 30px;
  }
`

export default function TitleDefault({ className, title, left }) {
  return (
    <Container className={`${className}`} left={left}>
      <Content content={title} className={` ${left ? 'mr-5' : ''} `} />
      <BorderLine />
    </Container>
  )
}

const BorderLine = styled.div`
  width: 100%;
  border-bottom: 5px solid ${(props) => props.theme.color.face.main};
  margin-bottom: 10px;
`
const Content = styled(ParseContent)`
  white-space: nowrap;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.font.size.xxxl};
  line-height: ${(props) => props.theme.font.size.xxxl};
  @media screen and (max-width: 992px) {
    font-size: ${(props) => props.theme.font.size.xl};
    line-height: ${(props) => props.theme.font.size.xl};
  }
`
